// consumer_web_policyConfig_updateGenerationCarPolicy 编辑代叫用车差标
const __request = require(`./__request/__request_contentType_json`);
// 接口文档地址：http://yapi.yinzhitravel.com/project/52/interface/api/15300
const consumer_web_policyConfig_updateGenerationCarPolicy = (pParameter) => {
    if (!pParameter) pParameter = {};
    const p = {
        method: 'post',
        urlSuffix: '/consumer/web/policyConfig/updateGenerationCarPolicy',
        data: pParameter
    };
    return new Promise(function(resolve, reject) {
        resolve(__request(p))
    })
};
module.exports = consumer_web_policyConfig_updateGenerationCarPolicy;
